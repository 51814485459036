<template>
  <div>
    <RadioGroup v-if="choices.length" v-model="value">
      <RadioGroupLabel class="sr-only"> Typ </RadioGroupLabel>
      <div class="flex-shrink-0 relative z-0 inline-flex shadow-sm rounded-md">
        <RadioGroupOption
          as="template"
          v-for="(option, index) in choices"
          :key="option.value"
          :value="option.value"
          v-slot="{ active, checked }"
        >
          <div
            :class="[
              active ? 'text-white ring-2 ring-offset-2 ring-ifgreen-dark' : '',
              checked
                ? 'bg-ifgreen border-transparent text-white hover:bg-ifgreen-dark'
                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
              index == 0 && 'rounded-l-md',
              index == (choices.length - 1) && 'rounded-r-md',
              wide ? 'w-24' : 'w-20',
              'justify-center cursor-pointer relative inline-flex items-center px-2 py-2 border text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen',
            ]"
          >
            <RadioGroupLabel as="span">{{ option.name }}</RadioGroupLabel>
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
  </div>
</template>

<script>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
export default {
  props: {
    choices: Array,
    modelValue: String,
    wide: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },
  data() {
    return {
      value: null
    };
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
    modelValue: {
      immediate: true,
      handler () {
        this.value = this.modelValue
      }
    }
  }
};
</script>
